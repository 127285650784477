.App {
  text-align: center;
}
.LogoHeader {
  vertical-align: middle;
  text-align: center;
}
.noMargin {
  display: inline-block;
  line-height: normal;
  margin: 5px;
}
#logginModal {
  width: 35%;
}
.material-icons.md-light {
  color: rgba(255, 255, 255, 1);
}
.material-icons.md-40 {
  font-size: 40px;
}

.altoParalax {
  height: 150px;
}
.caruselAlto {
  height: 10%;
}
.parallax {
  position: static;
}
.overflowTrue {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0%;
  top: 0%;
  overflow: auto;
}
.overflow {
  overflow: auto;
}
.padding {
  padding: 10px;
}

.anchoFotoProducto {
  width: 100%;
}
.marginProducto {
  margin: 10px;
  padding: 10px;
}
.finderRegistry {
  min-height: 50%;
  width: 90%;
}

.authorized-users-detail {
  display: flex;
  justify-content: space-evenly;
  height: 100%;
  overflow: hidden;
  padding: 10px;
}

.input-row {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  position: relative;
}

.input-row .MuiIconButton-root {
  padding-left: 5px;
  padding-right: 5px;
}

.input-row input {
  border-bottom: none;
}

.locations-checklist {
  flex: 1;
  height: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: start;
  width: 100%;
  margin-top: 20px;
}

.zoneCardText {
  margin-left: 40px;
}

.Mui-disabled {
  display: hidden;
}

.MuiIconButton-root.button-remove:hover {
  background-color: #36a9e1;
}

.MuiIconButton-root.button-edit:hover {
  background-color: #2ab7a9;
}

button.button-remove:focus,
button.button-edit:focus {
  background-color: transparent;
}

@media only screen and (max-width: 992px) {
  #logginModal {
    width: 80%;
  }
}
