body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

@font-face {
  font-family: "Poppins-Regular";
  src: local("Poppins-Regular"),
    url(./fonts/Poppins-Regular.ttf) format("opentype");
}
@font-face {
  font-family: "Poppins-Italic";
  src: local("Poppins-Italic"),
    url(./fonts/Poppins-Italic.ttf) format("opentype");
}
@font-face {
  font-family: "Poppins-Bold";
  src: local("Poppins-Bold"), url(./fonts/Poppins-Bold.ttf) format("opentype");
}
